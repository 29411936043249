.reddit-comment {
    text-decoration: none;
    text-underline: none;

    ul,
    ol {
        padding-left: 2rem;
        margin: 0.5rem 0;
    }

    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }

    table {
        margin: 0.75rem 0;

        th,
        td {
            border: 1px solid #ccc;
            padding: 0.375rem 0.5rem;
        }
    }

    blockquote {
        border-left: 2px solid #9ca3af;
        margin: 0.5rem 0.75rem;
        padding: 0.5rem 0.75rem;
        color: #9ca3af;

        ul,
        ol {
            margin: 0;
        }
    }

    blockquote + blockquote {
        margin-top: -0.25rem;
    }

    p {
        margin-bottom: 0;

        & + p {
            margin-top: 1rem;
        }
    }

    hr {
        margin: 0.5rem 0;
    }
}
