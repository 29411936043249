@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-date-range/dist/styles.css";
@import "~react-date-range/dist/theme/default.css";

body {
    min-width: 320px;
}

.text-decoration-none {
    text-decoration: none !important;
}

@import "loader";
@import "facet";
@import "custom-date-range";
@import "reddit-comment";
